import React from 'react';
import './ThankYou.css'; // Importez un fichier CSS si nécessaire

const ThankYouPage: React.FC = () => {
  return (
    <div className="thank-you-container">
      <h2>Merci !</h2>
      <p>Nous allons traiter votre demande dans les plus brefs délais.</p>
    </div>
  );
};

export default ThankYouPage;
