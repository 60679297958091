import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import "./UpdateProfileModal.css";

interface UpdateProfileModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  profile: {
    user_id: number;
    name: string;
    email: string;
    profile_photo_url?: string;
  } | null;
  onProfileUpdated: (updatedProfile: any) => void;
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({ isOpen, onRequestClose, profile, onProfileUpdated }) => {
  const [updatedProfile, setUpdatedProfile] = useState({
    name: '',
    email: '',
    profile_photo_url: ''
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    if (profile) {
      setUpdatedProfile({
        name: profile.name,
        email: profile.email,
        profile_photo_url: profile.profile_photo_url || ''
      });
    }
  }, [profile]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!profile) return;

    const token = localStorage.getItem('token');
    if (!token) {
        toast.error('Utilisateur non authentifié');
        return;
    }

    try {
        // Étape 1 : Obtenir l'URL présignée
        const presignedResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-user-upload-url`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                file_name: selectedFile?.name,
                file_type: selectedFile?.type
            })
        });

        const presignedData = await presignedResponse.json();

        // Étape 2 : Téléverser l'image vers S3 en utilisant l'URL présignée
        const formDataForS3 = new FormData();
        Object.keys(presignedData.fields).forEach(key => {
            formDataForS3.append(key, presignedData.fields[key]);
        });
        formDataForS3.append('file', selectedFile!);

        const uploadResponse = await fetch(presignedData.url, {
            method: 'POST',
            body: formDataForS3
        });

        if (uploadResponse.ok) {
            const imageUrl = `${presignedData.url}${presignedData.fields.key}`;

            // Étape 3 : Mise à jour du profil de l'utilisateur avec l'URL de l'image
            const updateResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    name: updatedProfile.name,
                    email: updatedProfile.email,
                    profile_photo_url: imageUrl
                })
            });

            if (updateResponse.ok) {
                const data = await updateResponse.json();
                toast.success('Profil mis à jour avec succès!');
                onProfileUpdated(data);
                onRequestClose();
            } else {
                const errorData = await updateResponse.json();
                toast.error(errorData.message || 'Échec de la mise à jour du profil');
            }
        } else {
            toast.error('Échec du téléversement du fichier vers S3');
        }
    } catch (error) {
        console.error('Erreur lors de la mise à jour du profil:', error);
        toast.error('Une erreur est survenue lors de la mise à jour du profil');
    }
};

  if (!profile) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modifier les informations du profil"
      className="modal-update-profile-content"
      overlayClassName="modal-update-profile-overlay"
    >
      <h2>Modifier les informations du profil</h2>
      <form>
        <div className="form-group">
          <label>Nom</label>
          <input type="text" name="name" value={updatedProfile.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" value={updatedProfile.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Télécharger une nouvelle photo</label>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>
        <div className="button-group">
          <button type="button" onClick={handleSave} className="save-button">Sauvegarder</button>
          <button type="button" onClick={onRequestClose} className="cancel-button">Annuler</button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateProfileModal;
