import React from 'react';
import './RegisterPension.css';
import { Link } from 'react-router-dom';

const RegisterPension: React.FC = () => {
  return (
    <div className="register-pension-container">
      <div className="register-steps-container">
        <div className="register-step">
          <h2 className="register-title">Vous êtes référencé sur Rintintin et vous aimeriez modifier, ajouter ou supprimer des informations ?</h2>
          <h3 className="register-subtitle">Ça se passe en un clic !</h3>
          <Link to="/register-form" className="register-cta-link">
            <button className="register-cta-button">
              Je rejoins la communauté Rintintin
            </button>
          </Link>
          <div className="register-image-container">
            <img src="https://rintintin-bucket.s3.eu-west-3.amazonaws.com/Screenshot+2024-09-01+at+16.29.05.png" alt="Jean Treins" className="register-profile-image" />
            <div className="register-question-mark-container">
              <p>C'est vous ?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPension;
