import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TrainerDetail.css';

interface Trainer {
  id: number;
  name: string;
  description: string;
  profile_photo_url: string;
  education_methods: string[];
  specialties: string[];
  services: string[];
  photos: string[];
  ville: string; // Adding ville to the Trainer interface
}

const TrainerDetail: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (id) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-trainer/${id}`)
        .then(response => {
          setTrainer(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching trainer:', error);
          setLoading(false);
        });
    }
  }, [id]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const nextImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex + 1) % trainer.photos.length);
    }
  };

  const prevImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex - 1 + trainer.photos.length) % trainer.photos.length);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!trainer) {
    return <div>Trainer not found</div>;
  }

  return (
    <div className="trainer-detail">
      <div className="trainer-detail-main">
        <div className="trainer-detail-content">
          <BackButton onClick={handleBackButtonClick} />
          <TrainerCarousel 
            photos={trainer.photos} 
            currentImageIndex={currentImageIndex} 
            nextImage={nextImage} 
            prevImage={prevImage} 
          />
          <TrainerInfo trainer={trainer} />
        </div>
        <div className="trainer-referenced-section">
          <h2>Êtes-vous ce formateur ?</h2>
          <p>Contactez-nous pour pouvoir gérer les réservations facilement !</p>
          <button className="contact-button" onClick={() => navigate('/contact')}>Nous contacter</button>
        </div>
      </div>
    </div>
  );
};

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button className="carousel-button" onClick={onClick}>⬅</button>
);

const TrainerCarousel: React.FC<{
  photos: string[];
  currentImageIndex: number;
  nextImage: () => void;
  prevImage: () => void;
}> = ({ photos, currentImageIndex, nextImage, prevImage }) => (
  <div className="carousel">
    {photos.length > 1 && (
      <button className="carousel-nav-button left" onClick={prevImage}>⬅</button>
    )}
    <img src={photos[currentImageIndex]} alt="Trainer" className="carousel-image" />
    {photos.length > 1 && (
      <button className="carousel-nav-button right" onClick={nextImage}>➡</button>
    )}
  </div>
);

const TrainerInfo: React.FC<{ trainer: Trainer }> = ({ trainer }) => (
  <div className="trainer-info">
    <h1>{trainer.name}</h1>
    <p className="trainer-ville">📍 {trainer.ville}</p>
    <p>{trainer.description}</p>
    {trainer.education_methods && trainer.education_methods.length > 0 && (
      <TrainerEducationMethods methods={trainer.education_methods} />
    )}
    {trainer.specialties && trainer.specialties.length > 0 && (
      <TrainerSpecialties specialties={trainer.specialties} />
    )}
    {trainer.services && trainer.services.length > 0 && (
      <TrainerServices services={trainer.services} />
    )}
  </div>
);

const TrainerEducationMethods: React.FC<{ methods: string[] }> = ({ methods }) => (
  <div className="trainer-education-methods">
    <h2>🧑‍🏫 Méthodes d'éducation</h2>
    <ul>
      {methods.map((method, index) => (
        <li key={index}>{method}</li>
      ))}
    </ul>
  </div>
);

const TrainerSpecialties: React.FC<{ specialties: string[] }> = ({ specialties }) => (
  <div className="trainer-specialties">
    <h2>💼 Spécialités</h2>
    <ul>
      {specialties.map((specialty, index) => (
        <li key={index}>{specialty}</li>
      ))}
    </ul>
  </div>
);

const TrainerServices: React.FC<{ services: string[] }> = ({ services }) => (
  <div className="trainer-services">
    <h2>💼 Services</h2>
    <ul>
      {services.map((service, index) => (
        <li key={index}>{service}</li>
      ))}
    </ul>
  </div>
);

export default TrainerDetail;
