import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import PensionCard from '../../components/PensionCard/PensionCard';
import SearchBar from '../../components/SearchBar/SearchBar';
// import { MapComponent } from '../../components/Map/MapComponent';
import { ClipLoader } from 'react-spinners';
import './PensionList.css';// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import './MapComponent.css';

// const DefaultIcon = L.icon({
//   iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
//   shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   shadowSize: [41, 41],
// });

// L.Marker.prototype.options.icon = DefaultIcon;

interface Pension {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  maxCapacity: number;
  currentOccupancy: number;
  rating: number;
  description: string;
  imageUrls: string[];
  distance_km?: number;
  status: string;
  lat: number;
  lon: number;
}

interface MapComponentProps {
  center: { lat: number; lon: number } | null;
  pensions: Pension[];
}

export const MapComponent: React.FC<MapComponentProps> = ({ center, pensions }) => {
  // const mapRef = useRef<L.Map | null>(null);

  // useEffect(() => {
  //   if (mapRef.current && center) {
  //     mapRef.current.setView([center.lat, center.lon], 12, {
  //       animate: true,
  //     });
  //   }
  // }, [center]);

  // if (!center) {
  //   return null; // Si pas de centre, ne pas afficher la carte
  // }

  return null; // La carte est désactivée pour le moment

  // return (
  //   <MapContainer
  //     style={{ height: '500px', width: '100%' }}
  //     zoom={12}
  //     whenReady={(mapInstance) => {
  //       mapRef.current = mapInstance;
  //       mapInstance.setView([center.lat, center.lon], 12);
  //     }}
  //   >
  //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
  //     {pensions.map((pension) => (
  //       pension.lat !== undefined && pension.lon !== undefined ? (
  //         <Marker key={pension.id} position={[pension.lat, pension.lon]}>
  //           <Popup>
  //             <b>{pension.name}</b>
  //             <br />
  //             {pension.address}
  //             <br />
  //             {pension.phone}
  //           </Popup>
  //         </Marker>
  //       ) : null
  //     ))}
  //   </MapContainer>
  // );
};




interface Pension {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  maxCapacity: number;
  currentOccupancy: number;
  rating: number;
  description: string;
  imageUrls: string[];
  distance_km?: number;
  status: string;
  lat: number;
  lon: number;
}

const PensionList: React.FC = () => {
  const location = useLocation();
  const { address, coordinates } = location.state || {};
  const [pensions, setPensions] = useState<Pension[]>([]);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState<{ lat: number; lon: number } | null>(coordinates || null);
  //const [shouldShowMap, setShouldShowMap] = useState<boolean>(!!coordinates);

  const fetchPensions = async (address?: string, center?: { lat: number; lon: number } | null) => {
    setLoading(true);
    try {
      const params: any = {};
      if (address) params.address = address;
      if (center) {
        params.lat = center.lat;
        params.lon = center.lon;
      }

      console.log('Fetching pensions with center:', center);

      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-pensions`, { params });
      const fetchedPensions: Pension[] = response.data.pensions;

      //setShouldShowMap(!!center);
      setPensions(fetchedPensions);
    } catch (error) {
      console.error('Error fetching pensions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Running useEffect - Address:', address, 'Center:', center);
    fetchPensions(address, center);
  }, [address, center]);

  const handleSearch = async (address: string) => {
    if (address) {
      console.log('Searching for address:', address);
      try {
        const coords = await getCoordinates(address);
        console.log('Coordinates fetched:', coords);

        if (coords && coords[0] !== 0 && coords[1] !== 0) {
          const newCenter = { lat: coords[0], lon: coords[1] };
          setCenter(newCenter);
        } else {
          console.error('No valid coordinates found for address');
          setCenter(null);
        }
      } catch (error) {
        console.error('Error while fetching coordinates', error);
        setCenter(null);
      }
    } else {
      console.log('No address provided, resetting map and center');
      setCenter(null);
      //setShouldShowMap(false);
    }
  };

  return (
    <div className="pension-list-container">
      <SearchBar withMarginTop={false} onSearch={handleSearch} />
      <div className="content-container">
        <div className="pension-cards">
          {loading ? (
            <ClipLoader size={50} color={'#e0b127'} loading={loading} />
          ) : (
            pensions.map((pension) => (
              <PensionCard
                key={pension.id}
                id={pension.id}
                imageUrls={pension.imageUrls}
                name={pension.name}
                rating={pension.rating}
                address={pension.address}
                description={pension.description}
                distanceKm={pension.distance_km}
              />
            ))
          )}
        </div>
        {/* {shouldShowMap && center && (
          <div className="map-container">
            <MapComponent pensions={pensions} center={center} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default PensionList;

async function getCoordinates(address: string): Promise<[number, number]> {
  try {
    console.log(`Fetching coordinates for ${address}`);
    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: address,
        format: 'json',
        limit: 1,
      },
    });

    if (response.data && response.data.length > 0) {
      const lat = parseFloat(response.data[0].lat);
      const lon = parseFloat(response.data[0].lon);
      console.log(`Coordinates for ${address}: ${lat}, ${lon}`);
      return [lat, lon];
    } else {
      console.error('No coordinates found for address');
      return [0, 0];
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    throw error;
  }
}
