import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sendEmail from '../../utils/emailForm';
import './RegisterForm.css';

const RegisterForm: React.FC = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    entreprise: '',
    objet: '',
    adresse: '',
    email: '',
    telephone: '',
    message: '',
  });

  const navigate = useNavigate(); // Définir useNavigate

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendEmail(formData);
      toast.success('Email envoyé avec succès');
      navigate('/thank-you'); // Rediriger vers la page de remerciement
    } catch (error) {
      toast.error('Erreur lors de l\'envoi de l\'email');
    }
  };

  return (
    <div className="register-form-container">
      <h2 className="register-form-title">Dites-nous qui vous êtes</h2>
      <form className="register-form" onSubmit={handleSubmit}>
        <input type="text" name="nom" placeholder="Nom" onChange={handleChange} value={formData.nom} required />
        <input type="text" name="prenom" placeholder="Prénom" onChange={handleChange} value={formData.prenom} required />
        <input type="text" name="entreprise" placeholder="Nom d’entreprise" onChange={handleChange} value={formData.entreprise} required />
        <input type="text" name="objet" placeholder="Objet d’entreprise" onChange={handleChange} value={formData.objet} />
        <input type="text" name="adresse" placeholder="Adresse" onChange={handleChange} value={formData.adresse} />
        <input type="email" name="email" placeholder="Email" onChange={handleChange} value={formData.email} required />
        <input type="tel" name="telephone" placeholder="Numéro de téléphone" onChange={handleChange} value={formData.telephone} required />
        <textarea
          name="message"
          placeholder="Votre message"
          onChange={handleChange}
          value={formData.message}
          rows={5}
        />
        <button type="submit" className="register-form-cta-button">Envoyer</button>
      </form>
    </div>
  );
};

export default RegisterForm;
