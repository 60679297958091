import React, { useState, useEffect } from 'react';
import './ModalUpdatePension.css';

interface ModalProps {
  show: boolean;
  handleClose: () => void;
  handleSave: (updatedPension: any) => void;
  pension: any;
}

const ModalComponent: React.FC<ModalProps> = ({ show, handleClose, handleSave, pension }) => {
  const [updatedPension, setUpdatedPension] = useState(pension);
  const [availableEquipments, setAvailableEquipments] = useState<any[]>([]);
  const [equipmentMap, setEquipmentMap] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    setUpdatedPension(pension);
  }, [pension]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/get-equipments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setAvailableEquipments(data.equipments);
      const map: { [key: string]: number } = {};
      data.equipments.forEach((equipment: any) => {
        map[equipment.name] = equipment.id;
      });
      setEquipmentMap(map);
    })
    .catch(error => console.error('Erreur lors du chargement des équipements:', error));
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedPension((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleEquipmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setUpdatedPension((prev: any) => ({ ...prev, equipment: selectedOptions }));
  };

  const handleImageUrlsChange = (index: number, value: string) => {
    const newImageUrls = [...updatedPension.image_urls];
    newImageUrls[index] = value;
    setUpdatedPension((prev: any) => ({ ...prev, image_urls: newImageUrls }));
  };

  const addImageUrl = () => {
    setUpdatedPension((prev: any) => ({ ...prev, image_urls: [...prev.image_urls, ''] }));
  };

  const removeImageUrl = (index: number) => {
    const newImageUrls = updatedPension.image_urls.filter((_: any, i: number) => i !== index);
    setUpdatedPension((prev: any) => ({ ...prev, image_urls: newImageUrls }));
  };

  const onSave = () => {
    if (!updatedPension.pension_id) {
      console.error('Pension ID is missing!');
      return;
    }

    // Convertir les noms des équipements en identifiants avant d'envoyer au backend
    const equipmentIds = updatedPension.equipment?.map((name: string) => equipmentMap[name]) || [];

    // Créer l'objet des données mises à jour en incluant seulement les champs nécessaires
    const updatedPensionData = {
      id: updatedPension.pension_id, // Assurez-vous que l'ID est inclus avec la bonne clé
      name: updatedPension.name || '',
      address: updatedPension.address || '',
      phone: updatedPension.phone || '',
      email: updatedPension.email || '',
      max_capacity: updatedPension.max_capacity || 0,
      description: updatedPension.description || '',
      image_urls: updatedPension.image_urls || [],
      equipment_ids: equipmentIds,
    };

    console.log('Données envoyées au backend:', updatedPensionData);
    handleSave(updatedPensionData);
  };

  return (
    <div className={`modal-update-pension ${show ? 'show' : ''}`}>
      <div className="modal-update-pension-content">
        <span className="modal-update-pension-close" onClick={handleClose}>&times;</span>
        <h2>Modifier les informations de la pension</h2>
        <form className="modal-update-pension-form">
          <div className="modal-update-pension-form-group">
            <label>Nom :</label>
            <input type="text" name="name" value={updatedPension.name} onChange={handleChange} placeholder="Nom" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Adresse :</label>
            <input type="text" name="address" value={updatedPension.address} onChange={handleChange} placeholder="Adresse" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Téléphone :</label>
            <input type="text" name="phone" value={updatedPension.phone} onChange={handleChange} placeholder="Téléphone" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Email :</label>
            <input type="email" name="email" value={updatedPension.email} onChange={handleChange} placeholder="Email" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Capacité maximale :</label>
            <input type="number" name="max_capacity" value={updatedPension.max_capacity} onChange={handleChange} placeholder="Capacité maximale" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Description :</label>
            <textarea name="description" value={updatedPension.description} onChange={handleChange} placeholder="Description"></textarea>
          </div>
          <div className="modal-update-pension-form-group">
            <label>Heures d'ouverture :</label>
            <input type="text" name="hours" value={updatedPension.hours} onChange={handleChange} placeholder="Heures d'ouverture" />
          </div>
          <div className="modal-update-pension-form-group">
            <label>Équipements :</label>
            <select multiple value={updatedPension.equipment || []} onChange={handleEquipmentChange}>
              {availableEquipments.map(equipment => (
                <option key={equipment.id} value={equipment.name}>
                  {equipment.name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-update-pension-form-group">
            <label>URLs des images :</label>
            {updatedPension.image_urls.map((url: string, index: number) => (
              <div className="modal-update-pension-image-url-group" key={index}>
                <input 
                  type="text" 
                  value={url} 
                  onChange={(e) => handleImageUrlsChange(index, e.target.value)} 
                  placeholder="URL de l'image" 
                />
                <button type="button" onClick={() => removeImageUrl(index)}>Supprimer</button>
              </div>
            ))}
            <button type="button" className="modal-update-pension-add-image-url" onClick={addImageUrl}>Ajouter une URL d'image</button>
          </div>
          <button type="button" className="modal-update-pension-save-button" onClick={onSave}>Sauvegarder</button>
        </form>
      </div>
    </div>
  );
};

export default ModalComponent;
