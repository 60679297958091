import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TrainerCard.css';

interface TrainerCardProps {
  id: number;
  profilePhotoUrl: string;
  name: string;
  ville: string;
  description: string;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};

const TrainerCard: React.FC<TrainerCardProps> = ({ id, profilePhotoUrl, name, ville, description }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/trainers/${id}`);
  };

  return (
    <div className="trainer-card" onClick={handleCardClick}>
      <img src={profilePhotoUrl} alt={name} className="trainer-card-image" />
      <div className="trainer-card-content">
        <div className="trainer-card-header">
          <h2 className="trainer-card-title">{name}</h2>
          <p className="trainer-card-ville">{ville}</p>
        </div>
        <p className="trainer-card-description">{truncateText(description, 190)}</p>
      </div>
    </div>
  );
};

export default TrainerCard;
