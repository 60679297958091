import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './AdminPage.css';

const AdminPage: React.FC = () => {
  const [formData, setFormData] = useState({
    user_id: '',
    name: '',
    description: '',
    profile_photo_url: '',
    education_methods: [],
    specialties: [],
    services: [],
    photos: '',
  });

  const [educationMethods, setEducationMethods] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [services, setServices] = useState([]);

  // Fetch Education Methods
  const fetchEducationMethods = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-education-methods`);
      const data = await response.json();
      setEducationMethods(data.education_methods);
    } catch (error) {
      console.error('Erreur lors de la récupération des méthodes d\'éducation:', error);
    }
  };

  // Fetch Specialties
  const fetchSpecialties = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-specialties`);
      const data = await response.json();
      setSpecialties(data.specialties);
    } catch (error) {
      console.error('Erreur lors de la récupération des spécialités:', error);
    }
  };

  // Fetch Services
  const fetchServices = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-services`);
      const data = await response.json();
      setServices(data.services);
    } catch (error) {
      console.error('Erreur lors de la récupération des services:', error);
    }
  };

  useEffect(() => {
    fetchEducationMethods();
    fetchSpecialties();
    fetchServices();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMultiSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const options = e.target.options;
    const selectedValues = Array.from(options).filter(option => option.selected).map(option => option.value);
    setFormData({ ...formData, [e.target.name]: selectedValues });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-trainer-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          ...formData,
          education_methods: formData.education_methods,
          specialties: formData.specialties,
          services: formData.services,
          photos: formData.photos.split(',').filter(Boolean), // Filtrer pour supprimer les valeurs vides
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Profil du formateur créé avec succès!');
      } else {
        toast.error(data.message || 'Erreur lors de la création du profil.');
      }
    } catch (error) {
      toast.error('Erreur lors de la requête.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="admin-page-container">
      <h1>Créer un profil de formateur</h1>
      <form className="admin-page-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="user_id">ID Utilisateur (optionnel)</label>
          <input type="text" id="user_id" name="user_id" value={formData.user_id} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="name">Nom (obligatoire)</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label htmlFor="description">Description (optionnelle)</label>
          <textarea id="description" name="description" value={formData.description} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="profile_photo_url">URL Photo de profil (optionnelle)</label>
          <input type="text" id="profile_photo_url" name="profile_photo_url" value={formData.profile_photo_url} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="education_methods">Méthodes d'éducation (optionnelles)</label>
          <select id="education_methods" name="education_methods" multiple value={formData.education_methods} onChange={handleMultiSelectChange}>
            {educationMethods.map((method: any) => (
              <option key={method.id} value={method.id}>
                {method.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="specialties">Spécialités (optionnelles)</label>
          <select id="specialties" name="specialties" multiple value={formData.specialties} onChange={handleMultiSelectChange}>
            {specialties.map((specialty: any) => (
              <option key={specialty.id} value={specialty.id}>
                {specialty.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="services">Services (optionnels)</label>
          <select id="services" name="services" multiple value={formData.services} onChange={handleMultiSelectChange}>
            {services.map((service: any) => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="photos">URLs des photos (séparées par des virgules) (optionnel)</label>
          <input type="text" id="photos" name="photos" value={formData.photos} onChange={handleChange} />
        </div>
        <button type="submit">Créer le profil du formateur</button>
      </form>
    </div>
  );
};

export default AdminPage;
