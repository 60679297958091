import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import Calendar from 'react-calendar'; // Unused import, commented out
import 'react-calendar/dist/Calendar.css';
import './PensionDetail.css';
import AddDogModal from '../../modals/AddDogModal';
// import { toast } from 'react-toastify'; // Unused import, commented out

interface Pension {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  max_capacity: number;
  current_occupancy: number;
  rating: number;
  description: string;
  image_urls: string[];
  equipment: string[];
  hours: string;
  night_price: number;
  staff: { first_name: string; role: string; image_url: string }[];
  reviews: { name: string; date: string | null; rating: number; comment: string }[];
  status: string;
}

interface Dog {
  dog_id: number;
  name: string;
  breed: string;
  profile_photo_url: string;
}

const PensionDetail: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [pension, setPension] = useState<Pension | null>(null);
  const [loading, setLoading] = useState(true);
  // const [dateRange, setDateRange] = useState<[Date, Date] | null>(null); // Unused variable, commented out
  // const [numDogs] = useState(1); // Unused variable, commented out
  // const [fees] = useState(0); // Unused variable, commented out
  // const [isLoggedIn, setIsLoggedIn] = useState(false); // Unused variable, commented out
  // const [dogs, setDogs] = useState<Dog[]>([]); // Unused variable, commented out
  // const [selectedDog, setSelectedDog] = useState<string>('');  // Unused variable, commented out
  // const [user, setUser] = useState<{ name: string; token: string } | null>(null); // Unused variable, commented out
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // setIsLoggedIn(true); // Unused variable, commented out
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-profile`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        // setUser(response.data); // Unused variable, commented out
        // fetchDogs(response.data.user_id, token); // Comment out this function call as dogs is not used
      }).catch(error => {
        console.error('Error fetching user profile:', error);
        // setIsLoggedIn(false); // Unused variable, commented out
      });
    }

    if (id) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-pension/${id}`)
        .then(response => {
          const data = response.data;
          const staff = data.staff.map((member: any) => ({
            first_name: member.first_name,
            role: member.role,
            image_url: member.image_url
          }));
          setPension({ ...data, staff });
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching pension:', error);
          setLoading(false);
        });
    }
  }, [id]);

  const handleBackButtonClick = () => {
    navigate(-1);  // Navigate to the previous page
  };

  const nextImage = () => {
    if (pension && pension.image_urls.length > 1) {
      setCurrentImageIndex((currentImageIndex + 1) % pension.image_urls.length);
    }
  };

  const prevImage = () => {
    if (pension && pension.image_urls.length > 1) {
      setCurrentImageIndex((currentImageIndex - 1 + pension.image_urls.length) % pension.image_urls.length);
    }
  };

  const handleDogAdded = (newDog: Dog) => {
    // setDogs(prevDogs => [...prevDogs, newDog]); // This is commented out since dogs state is not used
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!pension) {
    return <div>Pension not found</div>;
  }

  return (
    <div className="pension-detail">
      <div className="pension-detail-main">
        <div className="pension-detail-content">
          <button className="carousel-button" onClick={handleBackButtonClick}>⬅</button>
          <div className="carousel">
            {pension.image_urls.length > 1 && (
              <button className="carousel-nav-button left" onClick={prevImage}>⬅</button>
            )}
            <img src={pension.image_urls[currentImageIndex]} alt={pension.name} className="carousel-image" />
            {pension.image_urls.length > 1 && (
              <button className="carousel-nav-button right" onClick={nextImage}>➡</button>
            )}
          </div>
          {pension.description && (
            <div className="pension-about">
              <p>{pension.description}</p>
            </div>
          )}
          <div className="pension-info">
            {pension.equipment && pension.equipment.length > 0 && (
              <div className="pension-equipment">
                <h2>🏠 Équipement</h2>
                <ul>
                  {pension.equipment.map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {pension.max_capacity > 0 && (
              <div className="pension-size">
                <h2>🐶 Taille de la pension</h2>
                <p>{pension.max_capacity}</p>
              </div>
            )}
            {pension.hours && (
              <div className="pension-hours">
                <h2>⏰ Horaires</h2>
                <p>{pension.hours}</p>
              </div>
            )}
          </div>
          {pension.staff && pension.staff.length > 0 && (
            <div className="pension-team">
              <h2>L'équipe</h2>
              <div className="team-members">
                {pension.staff.map((member: { first_name: string; role: string; image_url: string }, index: number) => (
                  <div key={index} className="team-member">
                    <img src={member.image_url} alt={member.first_name} />
                    <p><strong>{member.first_name}</strong></p>
                    <p>{member.role}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {pension.reviews && pension.reviews.length > 0 && (
            <div className="pension-reviews">
              <h2>Ce que vous en pensez</h2>
              <div className="reviews">
                {pension.reviews.map((review: { name: string; date: string | null; rating: number; comment: string }, index: number) => (
                  <div key={index} className="review">
                    <p>{review.name}</p>
                    <p>{review.date}</p>
                    <p>{'⭐'.repeat(review.rating)}</p>
                    <p>{review.comment}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="referenced-section">
          <h2>Cette pension est à vous ?</h2>
          <p>Contactez-nous pour pouvoir gérer les réservations facilement !</p>
          <button className="contact-button" onClick={() => navigate('/contact')}>Nous contacter</button>
        </div>
      </div>
      <AddDogModal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)} 
        onDogAdded={handleDogAdded} 
      />
    </div>
  );
};

export default PensionDetail;
