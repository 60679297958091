import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';
import texts from '../../texts.json';

interface Profession {
  id: number;
  name: string;
  slug: string;
}

interface SearchBarProps {
  onSearch: (address: string, professionId?: number | null) => void;
  withMarginTop?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, withMarginTop = false }) => {
  const [address, setAddress] = useState('');
  const [selectedProfession, setSelectedProfession] = useState<number | null>(null);
  const navigate = useNavigate(); 

  // Professions définies en dur
  const professions: Profession[] = [
    { id: 1, name: 'Pension canine', slug: 'pensions' },
    { id: 2, name: 'Éducateur canin', slug: 'trainers' },
  ];

  const getCoordinates = async (address: string): Promise<[number, number]> => {
    try {
        const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`;
        const response = await fetch(url);

        const data = await response.json();
        if (data && data.length > 0) {
            const lat = parseFloat(data[0].lat);
            const lon = parseFloat(data[0].lon);
            return [lat, lon];
        }
    } catch (error) {
        console.error('Error fetching coordinates:', error);
    }
    return [0, 0];
};

const handleSearch = async () => {
  // Si aucun métier n'est sélectionné, rediriger vers la page "GeneralSearch"
  if (selectedProfession === null) {
    navigate('/general-search');
    return;
  }

  const profession = professions.find(prof => prof.id === selectedProfession);
  if (!profession) {
    console.error('Profession non trouvée.');
    return;
  }

  let lat: number | null = null;
  let lon: number | null = null;

  if (address) {
    [lat, lon] = await getCoordinates(address);
  }

  if (lat !== null && lon !== null) {
    navigate(`/${profession.slug}`, { state: { address, coordinates: { lat, lon }, professionId: selectedProfession } });
  } else {
    navigate(`/${profession.slug}`, { state: { professionId: selectedProfession } });
  }
};

  return (
    <div className={`search-bar-container ${withMarginTop ? 'margin-top' : ''}`}>
      <div className="search-bar">
        <div className="search-input-container">
          <label htmlFor="location">{texts.city}</label>
          <input
            id="location"
            type="text"
            placeholder={texts.perfectPlace}
            className="search-input"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="separator"></div>
        <div className="search-input-container">
          <label htmlFor="profession">{texts.iNeedAProfessional}</label>
          <select
            id="profession"
            className="search-select"
            value={selectedProfession ?? ''}
            onChange={(e) => setSelectedProfession(e.target.value ? Number(e.target.value) : null)}
          >
            <option value="">{texts.selectProfession}</option>
            {professions.map((profession) => (
              <option key={profession.id} value={profession.id}>
                {profession.name}
              </option>
            ))}
          </select>
        </div>
        <button className="search-btn" onClick={handleSearch}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M10 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm8.293 1.707l4.707 4.707-1.414 1.414-4.707-4.707c-.391.391-.902.707-1.414.707s-1.023-.316-1.414-.707c-.391-.391-.707-.902-.707-1.414s.316-1.023.707-1.414c.391-.391.902-.707 1.414-.707s1.023.316 1.414.707c.391.391.707.902.707 1.414s-.316 1.023-.707 1.414c-.391.391-.902.707-1.414.707s-1.023-.316-1.414-.707z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
