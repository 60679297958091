import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import "./UpdateDogModal.css";

interface UpdateDogModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  dog: any;
  onDogUpdated: (updatedDog: any) => void;
}

const UpdateDogModal: React.FC<UpdateDogModalProps> = ({ isOpen, onRequestClose, dog, onDogUpdated }) => {
  const [updatedDog, setUpdatedDog] = useState(dog);
  const [breeds, setBreeds] = useState<{ label: string, value: string }[]>([]);
  const [newProfilePhoto, setNewProfilePhoto] = useState<File | null>(null);

  useEffect(() => {
    setUpdatedDog(dog);
  }, [dog]);

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-dog-breeds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setBreeds(data.breeds.map((breed: { breed_id: number, name: string }) => ({ label: breed.name, value: breed.name })));
        } else {
          console.error('Failed to fetch dog breeds');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchBreeds();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
  
    // Vérification pour s'assurer qu'il s'agit d'un input de type fichier
    if (e.target instanceof HTMLInputElement && e.target.files && name === 'profile_photo') {
      setNewProfilePhoto(e.target.files[0]); // Set the new photo
    } else {
      setUpdatedDog({ ...updatedDog, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      let imageUrl = updatedDog.profilePhotoUrl;

      // If a new profile photo is selected, upload it
      if (newProfilePhoto) {
        const presignedResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-upload-url`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            file_name: newProfilePhoto.name,
            file_type: newProfilePhoto.type
          })
        });

        const presignedData = await presignedResponse.json();
        const formDataForS3 = new FormData();
        Object.keys(presignedData.fields).forEach(key => {
          formDataForS3.append(key, presignedData.fields[key]);
        });
        formDataForS3.append('file', newProfilePhoto);

        const uploadResponse = await fetch(presignedData.url, {
          method: 'POST',
          body: formDataForS3
        });

        if (uploadResponse.ok) {
          imageUrl = `${presignedData.url}${presignedData.fields.key}`;
        } else {
          toast.error('Failed to upload the new profile photo');
          return;
        }
      }

      // Update dog profile with new data
      const updateResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-dog-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          dog_id: updatedDog.dogId,
          name: updatedDog.name,
          breed: updatedDog.breed,
          birthdate: updatedDog.birthdate,
          information: updatedDog.information,
          profile_photo_url: imageUrl  // Use the new image URL if available
        })
      });

      if (updateResponse.ok) {
        toast.success('Dog profile updated successfully!');
        onDogUpdated(updatedDog);
        onRequestClose();
      } else {
        toast.error('Failed to update dog profile');
      }
    } catch (error) {
      toast.error('An error occurred while updating the dog profile');
      console.error('Error:', error);
    }
  };

  if (!dog) {
    return null; // Return null if no dog is selected
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modifier les informations du chien"
      className="modal-update-dog-content"
      overlayClassName="modal-update-dog-overlay"
    >
      <h2>Modifier les informations du chien</h2>
      <form>
        <div className="form-group">
          <label>Nom</label>
          <input type="text" name="name" value={updatedDog?.name || ''} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Race</label>
          <select name="breed" value={updatedDog?.breed || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une race</option>
            {breeds.map((breed) => (
              <option key={breed.value} value={breed.value}>{breed.label}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Date de naissance</label>
          <input type="date" name="birthdate" value={updatedDog?.birthdate || ''} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Informations</label>
          <textarea name="information" value={updatedDog?.information || ''} onChange={handleChange}></textarea>
        </div>
        <div className="form-group">
          <label>Photo de profil</label>
          <input type="file" name="profile_photo" onChange={handleChange} />
        </div>
        <div className="button-group">
          <button type="button" onClick={handleSave}>Sauvegarder</button>
          <button type="button" onClick={onRequestClose}>Annuler</button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateDogModal;
