import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import { toast } from 'react-toastify';
import './PasswordForgotten.css';
import sendEmail from '../../utils/emailForm';

const PasswordForgottenPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Définir useNavigate

  const handlePasswordReset = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await sendEmail({
        email,
        subject: 'Réinitialisation du mot de passe',
        message: `Adresse mail: ${email} a perdu son mot de passe.`,
      });
      toast.success('Un email a été envoyé à l\'administrateur.');
      navigate('/thank-you'); // Rediriger vers la page de remerciement
    } catch (error) {
      toast.error('Erreur lors de l\'envoi de l\'email.');
    }
  };

  return (
    <div className="password-forgotten-container">
      <h2>Réinitialiser le mot de passe</h2>
      <form onSubmit={handlePasswordReset}>
        <div className="form-group">
          <label htmlFor="email">Adresse e-mail :</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Réinitialiser le mot de passe</button>
      </form>
    </div>
  );
};

export default PasswordForgottenPage;
