// src/pages/AboutPage/AboutPage.tsx
import React from 'react';
import './About.css';

const AboutPage: React.FC = () => {
  return (
    <div className="about-page">
      <h1>À propos de Rintintin</h1>
      <p>
        Bienvenue sur Rintintin, votre plateforme de confiance pour trouver la pension idéale pour vos compagnons de vie.
      </p>
      <p>
        Notre mission est de faciliter la recherche de pensions de qualité pour vos animaux de compagnie, en vous offrant une expérience utilisateur simple et intuitive.
      </p>
      <p>
        Nous travaillons avec les meilleures pensions et éducateurs canins pour vous offrir des services de qualité et garantir le bien-être de vos animaux.
      </p>
    </div>
  );
};

export default AboutPage;
