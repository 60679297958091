import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import './DogPage.css';
import AddDogModal from '../../modals/AddDogModal';
import UpdateDogModal from '../../modals/UpdateDogModal'; // Import de la modale d'update
import Modal from 'react-modal';
import { toast } from 'react-toastify';

const DogPage: React.FC = () => {
  const { user } = useAuth();
  const [dogs, setDogs] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false); // État pour la modale d'update
  const [selectedDogId, setSelectedDogId] = useState<number | null>(null);
  const [selectedDog, setSelectedDog] = useState<any>(null); // Stocke le chien sélectionné pour l'update

  useEffect(() => {
    const fetchDogs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-dogs/${user.user_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched dogs:', data.dogs);
          setDogs(data.dogs);
        } else {
          console.error('Failed to fetch dogs');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (user) {
      fetchDogs();
    }
  }, [user]);

  const openConfirmModal = (dogId: number) => {
    setSelectedDogId(dogId);
    setConfirmModalIsOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
    setSelectedDogId(null);
  };

  const openUpdateModal = (dog: any) => {
    setSelectedDog(dog);
    setUpdateModalIsOpen(true);
  };

  const closeUpdateModal = () => {
    setUpdateModalIsOpen(false);
    setSelectedDog(null);
  };

  const deleteDog = async () => {
    if (!selectedDogId) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete-dog/${selectedDogId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                user_id: user.user_id
            })
        });

        if (response.ok) {
            setDogs(dogs.filter(dog => dog.dogId !== selectedDogId));
            closeConfirmModal();
            toast.success('Dog profile deleted successfully!');
        } else {
            const result = await response.json();
            toast.error(result.message || 'Failed to delete dog');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred while deleting the dog');
    }
  };

  const handleDogAdded = (newDog: any) => {
    setDogs([...dogs, newDog]);
  };

  const handleDogUpdated = (updatedDog: any) => {
    setDogs(dogs.map(dog => (dog.dogId === updatedDog.dogId ? updatedDog : dog)));
  };

  return (
    <div className="dog-background-container">
      <div className="dog-container">
        <h2>Mes Chiens</h2>
        {dogs.length > 0 ? (
          dogs.map(dog => (
            <div key={dog.dogId} className="dog-details">
              <img src={dog.profilePhotoUrl} alt={dog.name} />
              <div className="dog-info">
                <h3>{dog.name}</h3>
                <p>Race: {dog.breed}</p>
              </div>
              <button className="edit-icon" onClick={() => openUpdateModal(dog)}>✏️</button>
              <button className="delete-icon" onClick={() => openConfirmModal(dog.dogId)}>🗑️</button>
            </div>
          ))
        ) : (
          <p>Vous n'avez pas encore de chiens enregistrés.</p>
        )}
        <button className="add-dog-button" onClick={() => setModalIsOpen(true)}>
          Ajouter un chien
        </button>

        <AddDogModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          onDogAdded={handleDogAdded}
        />

        <UpdateDogModal
          isOpen={updateModalIsOpen}
          onRequestClose={closeUpdateModal}
          dog={selectedDog}
          onDogUpdated={handleDogUpdated}
        />

        <Modal
          isOpen={confirmModalIsOpen}
          onRequestClose={closeConfirmModal}
          contentLabel="Confirmer la suppression"
          overlayClassName="confirm-modal-overlay"
          className="confirm-modal-content"
        >
          <h2>Êtes-vous sûr de supprimer ?</h2>
          <div className="button-group">
            <button onClick={deleteDog} className="confirm-button">Confirmer</button>
            <button onClick={closeConfirmModal} className="cancel-button">Annuler</button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DogPage;
