const sendEmail = async (formData: any) => {
  const API_KEY = process.env.REACT_APP_BREVO_API_KEY;

  if (!API_KEY) {
    throw new Error("La clé API Brevo n'est pas définie dans les variables d'environnement.");
  }

  const url = 'https://api.brevo.com/v3/smtp/email';

  const emailData = {
    sender: { email: 'akseelmh@gmail.com', name: 'Rintintin Booking' }, // Utilise ton email comme expéditeur
    to: [{ email: 'contact@rintintin-booking.com', name: 'Recipient Name' }], // L'email destinataire
    subject: 'Nouveau formulaire soumis',
    htmlContent: `
      <h3>Formulaire soumis par ${formData.nom} ${formData.prenom}</h3>
      <p>Nom d'entreprise: ${formData.entreprise}</p>
      <p>Email: ${formData.email}</p> <!-- Ajoute l'email de l'utilisateur ici -->
      <p>Téléphone: ${formData.telephone}</p>
      <p>Adresse: ${formData.adresse}</p>
      <p><strong>Message:</strong> ${formData.message}</p>
    `,
  };

  try {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Détails de l\'erreur : ', errorData);
      throw new Error(`Erreur lors de l'envoi de l'email: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Réponse réussie : ', data);
    console.log('Email envoyé avec succès', data);
  } catch (error) {
    console.error('Erreur lors de l\'envoi de l\'email', error);
  }
};

export default sendEmail;
